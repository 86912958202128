<template>
    <!-- Елемент поля -->
    <div class="textfield">
        <div
            class="textfield_line"
            v-for="(item, index) in this.blocks" 
            :key="index"
            @mouseout="this.blocks[index].showMenu = false"
            @mouseover="this.blocks[index].showMenu = true"
        >
            <i 
                class='bx bx-plus textfield_line__icon'
                v-show="this.blocks[index].showMenu === true"
                @click="this.addElement(index)"
            ></i>
            <i 
                class='bx bx-menu textfield_line__icon'
                v-show="this.blocks[index].showMenu === true"
            ></i>
            <!-- v-if="this.blocks[index].edit === false" -->
            <div 
                v-html="this.returnTextInTag(item)"
                class="textfield_line__text" 
                :contenteditable="item.tag === 'input' ? false : true"
                @blur="inputContent(index)"
                @keyup="checkClick(index, $event)"
                @input="checkInput(index, $event)"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentEditIndex: null,
            content: '',
            blocks: [
                {
                    tag: 'h1',
                    content: 'Test h1',
                    showMenu: false,
                    edit: false
                },
                {
                    tag: 'h2',
                    content: 'Test h2',
                    showMenu: false,
                    edit: false
                },
            ]
        }
    },
    methods: {
        checkInput(index, event) {
            if(event.target.innerText.length > 0) {
                this.content = event.target.innerText.replace("Type...", "")
                this.currentEditIndex = index
            } else {
                if(this.blocks[index].tag != "input") {
                    this.blocks.splice(index, 1)
                }
            }
        },
        // Ця подія буде брати наш збережений текст і змінювати його в масиві контенту, зберігаючи
        inputContent(index) {
            if(this.blocks[index].tag === 'input') {
                this.blocks[index].tag = 'p'
            }
            
            if(this.currentEditIndex != null) {
                this.blocks[this.currentEditIndex].content = this.content
            }
            this.content = this.currentEditIndex = null
        },
        // Перевіряємо яку кнопку клікнули: 
        // Якщо це enter, то створюємо новий елемент p
        // Якщо delete, то видаляємо строку
        checkClick(index, event) {
            if(event.key === "Enter") {
                this.preventDefaultCustom(index, event.target)
            } else if(event.key === "Delete") {
                this.blocks.splice(index, 1)
            }
        },
        // Елемент видаляє блок в середині непотрібного елементу і додає його як кастомну строку
        preventDefaultCustom(index, element) {
            if(element.childNodes) {
                element.childNodes[element.childNodes.length - 2].remove()
            }
            
            if(this.blocks[index].tag === 'input') {
                this.blocks[index].tag = 'p'
            }
            this.addElement(index)
        },
        // Додаємо елемент після того, з котрим була дія
        addElement(index) {
            this.blocks.splice(index+1, 0, 
                {
                    tag: 'input',
                    content: '',
                    showMenu: false,
                    edit: true,
                    placeholder: 'Type...'
                }
            )
        },
        // Форматуємо наш текст в теги
        returnTextInTag(item) {
            return `
                <${item.tag} id="content" ${item.tag === 'input' ? 'placeholder="' + item.placeholder + '" style="border: none"' : ""}>
                    ${item.content}
                </${item.tag}>
            `
        },
    }
};
</script>

<style lang="css">
h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}
.textfield {
    display: grid;
    justify-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
}
.textfield_line {
    width: 60%;
    display: grid;
    grid-template-columns: 2% 2% 94%;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.textfield_line__text {
    grid-column-start: 3;
    grid-column-end: 4;
    padding-left: 10px;
}

.textfield_line__text:focus {
    outline: none;
}

.textfield_line__icon {
    font-size: 24px;
    cursor: pointer;
}
.form-control {
    grid-column-start: 3;
    grid-column-end: 4;
    border: none;
}

#content{
    border: none;
    outline: none;
}
</style>